import React from "react";

interface MySVGProps {
  fillColor: string;
  url: string;
}

const yt: React.FC<MySVGProps> = ({ fillColor, url }) => (
  <a href={url} target="_blank" rel="noreferrer">
    <svg
      width="24"
      height="18"
      viewBox="0 0 25 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M24.9942 6.7422C25.0504 5.07664 24.6949 3.42354 23.9608 1.93639C23.4627 1.32618 22.7715 0.914385 22.0075 0.772759C18.8476 0.478971 15.6746 0.358557 12.5021 0.412033C9.34122 0.35613 6.17965 0.472658 3.03083 0.761123C2.40829 0.877157 1.83218 1.17636 1.37279 1.62221C0.350706 2.58803 0.237141 4.24039 0.123576 5.63675C-0.0411922 8.14737 -0.0411922 10.6665 0.123576 13.1771C0.156431 13.963 0.270636 14.7432 0.46427 15.5044C0.601201 16.0921 0.87824 16.6358 1.27058 17.0869C1.73309 17.5564 2.32263 17.8726 2.96269 17.9945C5.41105 18.3042 7.87802 18.4325 10.3444 18.3785C14.3192 18.4367 17.8056 18.3785 21.928 18.0527C22.5838 17.9383 23.1899 17.6217 23.6655 17.1451C23.9835 16.8192 24.221 16.4203 24.3583 15.9815C24.7644 14.7045 24.9639 13.3681 24.9488 12.0251C24.9942 11.3735 24.9942 7.44038 24.9942 6.7422ZM9.93556 12.7233V5.52039L16.6586 9.13929C14.7734 10.2098 12.2864 11.42 9.93556 12.7233Z"
        fill={fillColor}
      />
    </svg>
  </a>
);

export default yt;
